import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AuthProvider } from "app/contexts/JWTAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MtkTheme } from "./components/shared";
import store from "./redux/store";
import routes from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const App = () => {
  const content = useRoutes(routes);

  const queryClient = new QueryClient();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <SettingsProvider>
            <MtkTheme>
              <AuthProvider>{content}</AuthProvider>
            </MtkTheme>
          </SettingsProvider>
        </Provider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default App;
