import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { COMMON_APIS } from "app/api/API";
import { API } from "app/utils/API";
import {CurrencyRatesListType} from "app/utils/types";

export const useGetCurrencyRatesList = (): UseQueryResult<
    CurrencyRatesListType[]
> => {
    return useQuery(["currency-rates-list"], async () => {
        return API(COMMON_APIS.getCurrencyRatesList, {
            authorization: true,
        }).then((res) => res?.data);
    });
};
