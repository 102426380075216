import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    styled,
    Checkbox,
    Box,
    Button,
    IconButton
} from '@mui/material';
import {useGetCurrencyList} from "../../services/finance/useGetCurrencyList";
import Loading from "../../components/Loading/MtkLoading";
import {Breadcrumb} from "../../components/shared";
import {useNavigate} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {DeleteModal} from "../../components/common/dialogs/DeleteModal";
import {useQueryClient} from "@tanstack/react-query";
import {useDeleteCurrency} from "../../services/finance/useDeleteCurrency";

const GetCurrenciesPage = () => {
    const [currentId, setCurrentId] = useState<number>();
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

    const navigate = useNavigate();
    const Container = styled("div")(({theme}) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: {margin: "16px"},
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: {marginBottom: "16px"},
        },
    }));

    const onNavigateToAddCurrencyPage = () => {
        navigate("add-currency");
    };

    const queryClient = useQueryClient();

    const deleteCurrency = useDeleteCurrency({
        onSuccess: async () => {
            queryClient.refetchQueries({
                queryKey: ["currency-list"],
            });
            handleCloseDeleteDialog();
        },
        onError: (_error: any) => {
            console.log("Error", _error);
        },
    });
    const handleConfirmDelete = () => {
        deleteCurrency.mutate(currentId);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const response = useGetCurrencyList()

    if (response.isLoading) {
        return <Loading/>;
    }

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{name: "Currencies", path: "/finance/currencies"}]}
                />
            </Box>
            <Button
                sx={{minWidth: "100px", marginBottom: "20px"}}
                variant="contained"
                color="primary"
                onClick={onNavigateToAddCurrencyPage}
            >
                Add Currency
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell>ISO Code</TableCell>
                        <TableCell>Main</TableCell>
                        <TableCell>National</TableCell>
                        <TableCell>Show in search</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {response.isSuccess && response.data.map(({
                                                                  id,
                                                                  name,
                                                                  code,
                                                                  codeIso,
                                                                  isMain,
                                                                  isNational,
                                                                  isShowForSearch
                                                              }) => (
                        <TableRow key={code}>
                            <TableCell>{id}</TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>{code}</TableCell>
                            <TableCell>{codeIso}</TableCell>
                            <TableCell>{isMain ? <Checkbox checked/> : <Checkbox disabled/>}</TableCell>
                            <TableCell>{isNational ? <Checkbox checked/> : <Checkbox disabled/>}</TableCell>
                            <TableCell>{isShowForSearch ? <Checkbox checked/> : <Checkbox disabled/>}</TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setCurrentId(id);
                                        handleOpenDeleteDialog();
                                    }}
                                >
                                    <DeleteIcon sx={{fontSize: 20}}/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {currentId && (
                <DeleteModal
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onConfirm={handleConfirmDelete}
                    disabled={deleteCurrency.isLoading}
                />
            )}
        </Container>
    );
};

export default GetCurrenciesPage;