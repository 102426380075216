import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { COMMON_APIS } from "app/api/API";
import { API } from "app/utils/API";

type Payload = {
  currencyId: number;
};

export function useDeleteCurrency(
  options?: Omit<UseMutationOptions<unknown, Payload>, "mutationFn">
): UseMutationResult<unknown, Payload, any> {
  return useMutation({
    ...options,
    mutationFn: async (currencyId: any): Promise<any | void> => {
      return API(COMMON_APIS.deleteCurrencyById(currencyId), {
        method: "DELETE",
        authorization: true,
      }).then((res) => res);
    },
  });
}
