import {lazy} from "react";
import Loadable from "app/components/shared/Loadable";
import {authRoles} from "app/auth/authRoles";
import GetCurrenciesPage from "../views/Finance/GetCurrenciesPage";
import GetCurrencyRatesPage from "../views/Finance/GetCurrencyRatesPage";

const PricePackage = Loadable(
    lazy(() => import("app/views/Price/PricePackage/PricePackage"))
);
const PriceBlockPage = Loadable(
    lazy(() => import("app/views/Price/PriceBlock/PriceBlock"))
);
const AddPriceBlockPage = Loadable(
    lazy(() => import("app/views/Price/PriceBlock/AddPriceBlockPage/AddPriceBlockPage"))
);
const EditPriceBlockPage = Loadable(
    lazy(() =>
        import("app/views/Price/PriceBlock/EditPriceBlockPage/EditPriceBlockPage")
    )
);
const ServicePricingPolicyPage = Loadable(
    lazy(() =>
        import("app/views/Price/PriceBlock/ServicePricingPolicy/ServicePricingPolicyPage")
    )
);

const FlightListPage = Loadable(
    lazy(() => import("app/views/Tours/AirTravel/Flight/FlightPage"))
);
const ViewEditFlightPage = Loadable(
    lazy(() => import("app/views/Tours/AirTravel/Flight/EditFlightPage"))
);
const AddFlightPage = Loadable(
    lazy(() => import("app/views/Tours/AirTravel/Flight/AddFlightPage"))
);

const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));
const AddCurrencyPage = Loadable(lazy(() => import('app/views/Finance/AddCurrency/AddCurrencyPage')));

const protectedRoutes = [
    {
        path: '/dashboard/default',
        element: <Analytics/>,
        auth: authRoles.admin
    },
    //flight
    {path: "/tourse/airtravel/fligth/list", element: <FlightListPage/>},
    {path: "/tourse/airtravel/fligth/create", element: <AddFlightPage/>},
    {
        path: "/tourse/airtravel/fligth/details/:flightId",
        element: <ViewEditFlightPage/>,
    },

    //price block
    {path: "/price/block", element: <PriceBlockPage/>},
    {path: "/price/block/price-block-add", element: <AddPriceBlockPage/>},
    {
        path: "/price/block/price-block/details/:priceBlockId",
        element: <EditPriceBlockPage/>,
    },
    {
        path: "/price/block/price-block/details/:priceBlockId/service-pricing-policy/:serviceId",
        element: <ServicePricingPolicyPage/>,
    },

    //price package
    {path: "/price/price-package", element: <PricePackage/>},

    //finance
    {path: "/finance/currencies", element: <GetCurrenciesPage/>},
    {path: "/finance/currencies/add-currency", element: <AddCurrencyPage/>},
    {path: "/finance/currency-rates", element: <GetCurrencyRatesPage/>},
];

export default protectedRoutes;
