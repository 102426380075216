import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    styled,
    Box,
    IconButton
} from '@mui/material';
import {useGetCurrencyRatesList} from "../../services/finance/useGetCurrencyRatesList";
import Loading from "../../components/Loading/MtkLoading";
import {Breadcrumb} from "../../components/shared";
import EditIcon from '@mui/icons-material/Edit';
import {useQueryClient} from "@tanstack/react-query";
import {EditCurrencyModal} from "../../components/common/dialogs/EditCurrencyModal";
import {useEditCurrencyRate} from "../../services/finance/useEditCurrencyRate";

const GetCurrencyRatesPage = () => {
    const [currentId, setCurrentId] = useState<number>();
    const [currentCurrencyId, setCurrentCurrencyId] = useState<number>();
    const [currentCodeIso, setCurrentCodeIso] = useState<string>();
     const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    const Container = styled("div")(({theme}) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: {margin: "16px"},
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: {marginBottom: "16px"},
        },
    }));


    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleOpenEditModal = () => {
        setOpenEditDialog(true);
    };

    const response = useGetCurrencyRatesList()

    if (response.isLoading) {
        return <Loading/>;
    }

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{name: "Currency rates", path: "/currency-rates"}]}
                />
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>currencyId</TableCell>
                        <TableCell>Code ISO</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell>Official rate</TableCell>
                        <TableCell>Internal rate</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {response.isSuccess && response.data.map(({   id,
                                                                  currencyId,
                                                                  codeIso,
                                                                  updateDate,
                                                                  officialRate,
                                                                  internaRate
                                                              }) => (
                        <TableRow key={id}>
                            <TableCell>{id}</TableCell>
                            <TableCell>{currencyId}</TableCell>
                            <TableCell>{codeIso}</TableCell>
                            <TableCell>{updateDate}</TableCell>
                            <TableCell>{officialRate}</TableCell>
                            <TableCell>{internaRate}</TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setCurrentId(id);
                                        setCurrentCurrencyId(currencyId);
                                        setCurrentCodeIso(codeIso);
                                        handleOpenEditModal();
                                    }}
                                >
                                    <EditIcon sx={{fontSize: 20}}/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {currentId && (
                <EditCurrencyModal
                    open={openEditDialog}
                    id={currentId}
                    currencyId={currentCurrencyId!}
                    codeIso={currentCodeIso}
                    onClose={handleCloseEditDialog}
                />
            )}
        </Container>
    );
};

export default GetCurrencyRatesPage;