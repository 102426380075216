import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { COMMON_APIS } from "app/api/API";
import { API } from "app/utils/API";

type Payload = {
  currencyData: any;
};

export function useEditCurrencyRate(
    options?: Omit<UseMutationOptions<unknown, Payload>, "mutationFn">
): UseMutationResult<unknown, Payload, any> {
  return useMutation({
    ...options,
    mutationFn: async (data: any): Promise<any | void> => {
      const payload = data.currencyData;
      return API(COMMON_APIS.editCurrencyRate, {
        method: "PUT",
        payload,
        authorization: true,
      }).then((res) => res);
    },
  });
}
