import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import Loadable from "./components/shared/Loadable";
import MtkLayout from "./components/MtkLayout/MtkLayout";
import protectedRoutes from "./utils/protectedRoutes";

const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MtkLayout />
      </AuthGuard>
    ),
    children: [...protectedRoutes],
  },

  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
