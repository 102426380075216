import { createContext, useEffect, useReducer } from "react";
import { MtkLoading } from "app/components/shared";
import { API } from "app/utils/API";
import { USER_API } from "app/api/API";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

export function clearSessionStorage() {
  sessionStorage.clear();
}
const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    try {
      const response = await API(USER_API.login, {
        method: "POST",
        payload: { email, password, userName: "", fullName: "" },
      });
      sessionStorage.setItem("accessToken", response.data.accessToken);
      sessionStorage.setItem("refreshToken", response.data.refreshToken);
      sessionStorage.setItem("expiresIn", response.data.expiresIn);
      dispatch({
        type: "LOGIN",
        payload: { user: { name: response.data.name } },
      });
    } catch (err) {
      console.log("error data", err.response.data);
    }
  };

  const register = async (email, username, password, fullname) => {
    try {
      const response = await API(USER_API.register, {
        method: "POST",
        payload: { email, username, password, fullname },
      });
      const { user } = response.data;
      dispatch({ type: "REGISTER", payload: { user } });
    } catch (err) {
      console.log("error data", err.response.data);
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    clearSessionStorage();
  };

  useEffect(() => {
    (async () => {
      try {
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")} `,
        };
        const { data } = await API(USER_API.profile, {
          customHeaders: headers,
        });
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: true, user: data },
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MtkLoading />;

  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
