import React, {useEffect} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
} from "@mui/material";
import {useForm} from "react-hook-form";
import {CurrencyEditDTO} from "../../../utils/types";
import {useNavigate} from "react-router-dom";
import {useEditCurrencyRate} from "../../../services/finance/useEditCurrencyRate";

export type Props = {
  text?: string;
  open: boolean;
  disabled?: boolean;
  id: number;
  currencyId: number;
  codeIso: string | undefined;
  onClose: () => void;
};

export const EditCurrencyModal: React.FC<Props> = ({
  id,
  currencyId,
  codeIso,
  open,
  onClose,
}) => {

  const {
    register,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm<CurrencyEditDTO>({
    mode: "all",
  });

  const editCurrency = useEditCurrencyRate({
    onSuccess: async (response: any) => {
      window.location.reload()
    },
    onError: (_error: any) => {
      console.log("Error", _error);
    },
  });
  const onEdit = (data: CurrencyEditDTO) => {
    editCurrency.mutate({
      currencyData: data,
    });
  };
  useEffect(() => {
    setValue("id", id)
    setValue("currencyId", currencyId)
  }, [currencyId, id, setValue]);

  return (
    <div>
      <Dialog open={open}>
        <Box minWidth="400px">
          <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {m: 2, width: 400},
              }}
              noValidate
              autoComplete="off"
          >
            <TextField
                id="outlined-basic"
                variant="outlined"
                label="Currency Code ISO"
                InputLabelProps={undefined}
                value={codeIso}
                disabled={true}
            />
            <TextField
                id="outlined-basic"
                variant="outlined"
                label="*Official Rate - required"
                {...register("officialRate", {
                  required: "Official Rate is required",
                })}
                InputLabelProps={undefined}
            />
            <TextField
                id="outlined-basic"
                variant="outlined"
                label="*Internal Rate - required"
                {...register("internaRate", {
                  required: "Internal Rate is required",
                })}
                InputLabelProps={undefined}
            />
          </Box>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={handleSubmit(onEdit)}>
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};
