/* eslint-disable no-redeclare */
// utils/API.js
import axios, { AxiosPromise, AxiosRequestConfig, Method } from "axios";

export type EndpointUrl = string;
type RequestOptions = AxiosRequestConfig & {
  method: Method;
  authorization: boolean;
  payload?: object;
  responseType?: ResponseType;
  customHeaders?: object;
  useNewInstance?: boolean;
};

export const instance = axios.create();

export type ResponseType =
  | "arraybuffer"
  | "document"
  | "json"
  | "text"
  | "stream";

export function API(
  endpoint: EndpointUrl,
  {
    method = "GET",
    authorization = false,
    payload,
    responseType,
    customHeaders,
    useNewInstance = false,
    ...other
  }: Partial<RequestOptions> = {}
): AxiosPromise {
  const headers: any = {
    ...customHeaders,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  if (authorization) {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  const data: AxiosRequestConfig = {
    method,
    url: endpoint,
    data: payload,
    headers,
    ...(responseType && { responseType }),
    ...other,
  };

  return useNewInstance ? axios(data) : instance(data);
}
