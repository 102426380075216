//import useAuth from 'app/hooks/useAuth';

import useAuth from "../../app/hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  console.log("AuthGuard");
  const { isAuthenticated } = useAuth();
  console.log("isAuthenticated", isAuthenticated);
  const { pathname } = useLocation();

  if (isAuthenticated) return <>{children}</>;
  console.log("user is not auth");
  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
};

export default AuthGuard;
