import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { COMMON_APIS } from "app/api/API";
import { API } from "app/utils/API";
import { CurrencyListType } from "app/utils/types";

export const useGetCurrencyList = (): UseQueryResult<
    CurrencyListType[]
> => {
    return useQuery(["currency-list"], async () => {
        return API(COMMON_APIS.getCurrencyList, {
            authorization: true,
        }).then((res) => res?.data);
    });
};
