/* Styled components (CSS-in-JS) */

/* Material UI to Styled Components */
import { styled } from '@mui/material/styles'

/* Material UI Components */
import DialogContent from '@mui/material/DialogContent'

export const DialogContentDetails = styled(DialogContent)(({ theme }) => ({
    containerType: 'inline-size',
    containerName: 'dialog-content',
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',

    '#double-content': {
        gridTemplateColumns: '1fr auto 1fr',
    },
    [`@container dialog-content (width < ${theme.breakpoints.values.sm}px)`]: {
        '#double-content': {
            gridTemplateColumns: '1fr',
        },
    },
}))
