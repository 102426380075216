// const HOST_REMOTE = "http://16.171.143.175:3000";
// const LOCAL_HOST = "http://localhost:7003";
const HOST = "https://backend.maratuktours.com";

export const USER_API = {
  login: `${HOST}/user`,
  register: `${HOST}/user/register`,
  profile: `${HOST}/user/auth/profile`,
};

export const COMMON_APIS = {
  priceBlock: `${HOST}/priceBlock`,
  priceBlockById: (priceBlockId: number) =>
    `${HOST}/priceBlock/${priceBlockId}`,
  serviceList: `${HOST}/priceBlock/priceBlockService`,
  serviceListById: (priceBlockId: number) =>
    `${HOST}/priceBlock/priceBlockServices/${priceBlockId}`,
  deleteService: (serviceId: number) =>
    `${HOST}/priceBlock/priceBlockService/${serviceId}`,
  getCountries: `${HOST}/admin/country`,
  getCities: (countryId: number) => `${HOST}/admin/city?countryId=${countryId}`,
  getCurrencyList: `${HOST}/admin/currency`,
  addCurrency: `${HOST}/admin/currency`,
  deleteCurrencyById: (currencyId: number) =>
      `${HOST}/admin/currency?id=${currencyId}`,
  getCurrencyRatesList: `${HOST}/admin/currencyRates`,
  editCurrencyRate: `${HOST}/admin/currencyRates`,
};

export const CREATE_SERVICE_API = {
  getOptions: `${HOST}/admin/countryFlight`,
  getFlightsByIds: (
    departureCountryId: number,
    departureCityId: number,
    destinationCountryId: number,
    destinationCityId: number
  ) =>
    `${HOST}/flight/flightByIds?departureCountryId=${departureCountryId}&departureCityId=${departureCityId}&destinationCountryId=${destinationCountryId}&destinationCityId=${destinationCityId}`,
  pricingPolicyById: (serviceId: number) =>
    `${HOST}/priceBlock/servicesPricingPolicy/${serviceId}`,
  pricingPolicy: `${HOST}/priceBlock/servicesPricingPolicy`,
};

export const OPTIONS_API = {
  getPriceBlockTypes: `${HOST}/admin/priceBlockType`,
  getPricePackageTypes: `${HOST}/pricePackage`,
  getServiceTypes: `${HOST}/admin/serviceClass`,
  getSeason: `${HOST}/admin/season`,
  getPartner: `${HOST}/admin/partner`,
  getCurrency: `${HOST}/admin/currency`,
  getRate: `${HOST}/admin/tarif`,
};

export const FLIGHT_API = {
  getFlightInfo: (flightId: number) => `${HOST}/flight/info/${flightId}`,
  flighById: (flightId: number) => `${HOST}/flight/${flightId}`,
  flight: `${HOST}/flight`,
  getAirlines: `${HOST}/admin/airline`,
  getAircraft: `${HOST}/admin/aircraft`,
  checkFlightName: `${HOST}/flight/isFlightNameExist`,
};

export const PRICE_PACKAGE_API = {
  pricePackage: `${HOST}/pricePackage`,
  pricePackageById: (pricePackageId: number) =>
    `${HOST}/pricePackage/${pricePackageId}`,
};
