import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DialogContentDetails } from "../styles/AddServiceListDialog.styles";

const defaultText = "Do you really want to delete this item";

export type Props = {
  text?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  disabled?: boolean;
};

export const DeleteModal: React.FC<Props> = ({
  text = defaultText,
  open,
  onClose,
  onConfirm,
  disabled = false,
}) => {
  return (
    <div>
      <Dialog open={open}>
        <Box minWidth="400px">
          <DialogTitle>Are you sure ?</DialogTitle>
          <DialogContentDetails>
            <Typography component="span">{text}</Typography>
          </DialogContentDetails>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" onClick={onClose} disabled={disabled}>
              No
            </Button>
            <Button variant="outlined" disabled={disabled} onClick={onConfirm}>
              Yes
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};
