import { MtkSuspense } from 'app/components/shared';
import useSettings from 'app/hooks/useSettings';
import { MtkLayouts } from './index';

const MtkLayout = (props) => {
  const { settings } = useSettings();
  const Layout = MtkLayouts[settings.activeLayout];

  return (
    <MtkSuspense>
      <Layout {...props} />
    </MtkSuspense>
  );
};

export default MtkLayout;
